<template>
  <div class="w-100">
    <div class="text-center empoyee-subsidy-message">{{ $t('product-page.employee-subsidy') }}</div>
    <div class="text-center maccabi-selection" :class="{'mx-2 mb-4': device==='mobile', 'w-50 mx-auto': device==='desktop'}">
      <!-- :dropdownShouldOpen="() => true" -->
      <v-select v-model="selectedDate" :options="ableFlightsOptions" :disabled="isFull" :searchable="false" dir="rtl" :selectable="optionSelectable" :clearable="false">
        <template #selected-option="{ startLabel, endLabel, duration }">
          <div class="mr-1" style="position: relative;">
            <span v-if="isFull">
              <span class="maccabi-selection-label align-self-center">{{ $t('product-page.all-place-sold-out') }}</span>
            </span>
            <span v-else-if="!isNotFirstEnter">
              <span class="maccabi-selection-label align-self-center">{{ $t('product-page.choose-date') }}</span>
            </span>
            <span style="text-wrap: nowrap;" v-else>
              {{startLabel}}-{{ endLabel }}-{{ duration }} {{ $t('search-result.night') }}<br/>
              <span class="maccabi-selection-label align-self-center">{{ $t('product-page.change-choosed-date') }}</span>
            </span>
          </div>
        </template>

        <template #list-header>
          <div class="option row maccabi-selection-header">
            <span style="width: 44%;" class="text-center"> {{ $t('booking.departure-date') }}</span>
            <span style="width: 44%;" class="text-center">{{ $t('booking.arrival-date') }}</span>
            <span style="width: 12%;" class="text-center">{{ $t('booking.no-nights') }}</span>
          </div>
        </template>

        <template v-slot:option="{ startLabel, endLabel, duration, disabled }">
          <div class="option row maccabi-selection-option" :class="disabled ? 'thin-line' : ''">
            <span style="width: 44%;"> {{ startLabel }}</span>
            <span style="width: 44%;">{{ endLabel }}<span v-if="disabled && device==='desktop'">({{ $t('calendar.run-out') }})</span></span>
            <span style="width: 12%;">{{ duration }}<span v-if="disabled && device==='mobile'" class="p-0">{{ $t('calendar.run-out') }}</span></span>
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'maccabi-available-flights-selection',
  props: {
    flightItems: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {
      selectedDate: null,
      isFull: false,
      initialValue: {
        startLabel: '0000-00-00 00:00',
        endLabel: '0000-00-00 00:00',
        duration: 0,
        value: -1,
        label: '',
        disabled: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isNotFirstEnter: 'GET_CHANGED_DATE_STATE_FOR_MACCABI_AGENCY',
    }),
    flights() {
      // const { flightItems, currentFlightNumber } = this;
      const { flightItems } = this;

      const aFlights = [...flightItems];

      // return aFlights.sort((a, b) => {
      //   if (currentFlightNumber === `${a.FlightDetail[0].FL_ID}${a.FlightDetail[1].FL_ID}`
      //   && currentFlightNumber !== `${b.FlightDetail[0].FL_ID}${b.FlightDetail[1].FL_ID}`) {
      //     return -1;
      //   } else if (currentFlightNumber !== `${a.FlightDetail[0].FL_ID}${a.FlightDetail[1].FL_ID}`
      //   && currentFlightNumber === `${b.FlightDetail[0].FL_ID}${b.FlightDetail[1].FL_ID}`) {
      //     return 1;
      //   } else {
      //     return (new Date(a.FlightDetail[0].FL_Date) - new Date(b.FlightDetail[0].FL_Date));
      //   }
      // });
      return aFlights.sort((a, b) => (new Date(a.FlightDetail[0].FL_Date) - new Date(b.FlightDetail[0].FL_Date)));
    },
    ableFlightsOptions() {
      const { flights } = this;

      return flights.map((flight, inx) => {
        const startDate = flight.FlightDetail[0].FL_Date;
        const startTime = flight.FlightDetail[0].FL_Dep_Hour;
        const startWeekName = this.weekDaysNames[dayjs(startDate).day()];
        const endDate = flight.FlightDetail[1].FL_Date;
        const endTime = flight.FlightDetail[1].FL_Dep_Hour;
        const endWeekName = this.weekDaysNames[dayjs(endDate).day()];

        const diff = dayjs(endDate).diff(startDate, 'day');

        return {
          startLabel: `${startWeekName} ${dayjs(startDate).format('DD/MM/YY')} ${startTime}`,
          endLabel: `${endWeekName} ${dayjs(endDate).format('DD/MM/YY')} ${endTime}`,
          duration: diff,
          value: inx,
          label: '',
          disabled: (flight.avl1 || 0) < 2 && (flight.avl2 || 0) < 2,
        };
      });
    },
    weekDaysNames() {
      return [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
    },
    currentQueryBody() {
      return this.$route.query;
    },
    currentDateFrom() {
      return this.currentQueryBody.dateFrom;
    },
    currentFlightNumber() {
      return this.currentQueryBody.flights;
    },
  },
  watch: {
    selectedDate() {
      if (this.selectedDate.value !== -1) { this.refreshWithNewFlight(); }
    },
  },
  mounted() {
    this.getCurrentFlight();
  },
  methods: {
    getCurrentFlight() {
      let inx = this.flights.findIndex((flight) => `${flight.FlightDetail[0].FL_ID}${flight.FlightDetail[1].FL_ID}` === this.currentFlightNumber);
      const isCurrentFull = (this.flights[inx].avl1 || 0) < 2 && (this.flights[inx].avl2 || 0) < 2;
      if (inx === -1 || (inx !== -1 && isCurrentFull)) {
        inx = this.flights.findIndex((flight) => ((flight.avl1 || 0) > 1 && (flight.avl2 || 0) > 1));
      }
      this.selectedDate = (inx === -1) ? this.initialValue : this.ableFlightsOptions[inx];
      if (inx === -1) {
        this.isFull = true;
        this.$store.commit('SET_IS_MACCABI_FULL', this.isFull);
      } else if (isCurrentFull) {
        this.refreshWithNewFlight(true);
      }
    },
    refreshWithNewFlight(isFirst) {
      const { selectedDate, flights } = this;
      const sltFlightIdx = selectedDate.value;
      const sltFlight = flights[sltFlightIdx];
      const sltFlightNumber = `${sltFlight.FlightDetail[0].FL_ID}${sltFlight.FlightDetail[1].FL_ID}`;

      if (sltFlightNumber === this.currentFlightNumber) return false;

      const body = { ...this.currentQueryBody };

      body.flights = sltFlightNumber;
      body.dateFrom = sltFlight.FlightDetail[0].FL_Date;

      this.$router.push({ path: this.$route.path, query: body });
      this.$emit('update');
      if (!isFirst) this.$store.commit('SET_CHANGED_DATE_FOR_MACCABI_AGENCY_PAGE', true);
      return true;
    },
    optionSelectable(option) {
      return !option.disabled;
    },
  },
};
</script>

<style>
  #did-you-know {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .maccabi-remark {
    font-size: 1.2rem;
  }
  .maccabi-selection .vs__selected-options{
    height: 3rem;
  }
  .maccabi-selection .vs__dropdown-option {
    padding: 0;
    border-top: solid 1px gray;
    text-align: center;
  }
  .maccabi-selection .vs__dropdown-option:last-child {
    border-bottom: solid 1px gray;
  }
  .maccabi-selection .v-select .vs__dropdown-menu {
    overflow-x: hidden;
    padding-bottom: 0;
  }
  /* .maccabi-selection.w-75 .vs--open {
    width: 75%;
  } */
  .maccabi-selection-label {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .maccabi-selection-header {
    margin: 0;
    font-weight: 600;
  }
  .maccabi-selection-header span:not(:last-child){
    border-left: solid 1px gray;
  }
  .maccabi-selection-header span{
    height: 80px;
    background-color: rgb(197, 222, 243);
    align-items: center;
    display: inline-grid;
  }

  .maccabi-selection-option {
    padding: 0;
    margin: 0;
  }
  .maccabi-selection-option span:not(:last-child){
    border-left: solid 1px gray;
  }
  .maccabi-selection-option span{
    align-items: flex-start;
    padding: 0.5rem;
    margin: 0;
  }

  .maccabi-selection .vs--single .vs__selected {
    display: block;
    margin: auto;
  }
  .maccabi-selection .vs__search {
    flex-grow: unset;
  }
  .maccabi-selection .vs--single.vs--open .vs__selected {
    position: inherit;
  }
  .empoyee-subsidy-message {
    font-size: 2rem;
    font-weight: bold;
    color: purple;
  }
  .thin-line::after {
    content: '';
    transform: translateY(-20px);
    border-top: 1px solid gray;
    width: 78%;
    margin-right: 25px;
  }
@media (max-width: 479px) {
  .empoyee-subsidy-message {
    font-size: 1.5rem;
  }
  .thin-line::after {
    width: 85%;
    margin-right: 5px;
  }
}
</style>
